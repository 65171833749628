<template>
  <section class="policy-holder">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <!--      personal-->
      <el-row
        class="row-bg"
        :gutter="10"
        justify="space-around">
        <v-row-col v-if="isShowSelect">
          <v-row-col
            same>
            <div class="form-item ">
              <span>Occupied As</span>
              <el-form-item prop="policy_holder_occ_id">
                <v-select
                  v-model="formData.insured_detail.occupied_as_id"
                  :list="globalOptions.occupied_as"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same>
            <div class="form-item ">
              <span>Property Age</span>
              <el-form-item prop="policy_holder_occ_id">
                <v-select
                  v-model="formData.insured_detail.property_age_id"
                  :list="globalOptions.property_age"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          v-if="isShowSelect"
        >
          <v-row-col
            :same="true"
          >
            <div class="form-item ">
              <span>Building Type</span>
              <el-form-item prop="policy_holder_occ_id">
                <v-select
                  v-model="formData.insured_detail.build_type_id"
                  :list="globalOptions.building_type"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.insured_detail.build_type_id==4"
            :same="true"
          >
            <div class="form-item ">
              <span>Other</span>
              <el-form-item prop="build_type">
                <v-input
                  v-model="formData.insured_detail.build_type"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
      <div class="flex flex-right">
        <v-checkbox
          v-model="formData.same_as_correspondence_address"
          class="theme-blue"
          @change="handlerSame"></v-checkbox>
        <span>Same as Correspondence Address</span>
      </div>
      <div class="">
        <div class="card">
          <address-form
            ref="address-form"
            :formDataLock="formDataLock"
            :formType="formType"
            :form="formData.insured_detail.proposed_location_of_risk"
            :title="'Proposed Location of Risk'"
            :isTitle="isTitle"
            :disabled="formData.same_as_correspondence_address==1"
            @getFormData="getSubFormData"/>
        </div>
      </div>
    </el-form>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import AddressForm from '@/views/components/address-form'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'inusredDetailForm',
  components: {
    AddressForm,
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    formType: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    },
    isShowSelect:{
      type: Boolean,
      default: true
    },
    isTitle:{
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      oldFormDataLock: -1,
      checked:false,
      formData: {
        same_as_correspondence_address:0,
        insured_detail:{
          build_type_id:'',
          build_type:'',
          property_age_id:'',
          occupied_as_id:'',
          product_detail:'',
          proposed_location_of_risk: {
            room: '',
            floor: '',
            block: '',
            building: '',
            street: '',
            district: '',
            area: '',
            region: ''
          },
        }
      },
      subForm: {
        'address-form': false
      },
    }
  },
  computed: {

    localAllOptions() {
      const classId = this.formData.class_id
      if (classId === 14) {
        return this.allOptions
      } else {
        return this[`allOptions${classId}`]
      }
    },
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          //   // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
          // 給 pp_no 賦值
          const {policy_holder_id_type, policy_holder_id_no,pp_no,insured_detail} = val
          if (policy_holder_id_type === 2 ) {
            this.formData.pp_no = policy_holder_id_no||pp_no
          }
          let {same_as_correspondence_address}  =insured_detail||{}
          this.formData.same_as_correspondence_address = same_as_correspondence_address
        }
        // 用于匹配是否更新了信息
        // this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      }
    },
    'formData.policy_holder_is_driver': {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formData.policy_holder_id_type === 2) {
          this.formData.policy_holder_id_no = this.formData.pp_no
        }
        this.$emit('one-driver', val == 1 ? true : false,this.formData)
        // // this.$emit('change-driver', this.formData)
      }
    },
  },
  methods: {
    handlerSame(){
      const {same_as_correspondence_address} = this.formData
      this.$emit('sameAddress', same_as_correspondence_address == 1 ? true : false)
    },
    sameAddress(data){
      let {correspondence_address} = data||{}
      this.formData.insured_detail.proposed_location_of_risk = correspondence_address
    },
    checkDisabled(field) {
      const {disable_filed = []} = this.form
      return disable_filed.includes(field)
    },
    openConfirmDialog() {
      this.$refs['confirm-information'].changeDialog()
    },
    handlerConfirmChangeInfo(result) {
      this.$emit('confirm', result)
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      let {correspondence_address} = data
      this.formData.insured_detail.proposed_location_of_risk = correspondence_address
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = this.formData
          if (params.policy_holder_id_type === 2) {
            params.policy_holder_id_no = params.pp_no
          }
          if(this.clientType==2){
            params.policy_holder_id_type=3
          }
          this.$emit('getFormData', formName, params)
        }else {
          if(is_draft)
          {
            this.$ConfirmBox({
              title: '',
              message: 'Please enter the policyholder name and mobile number',
              confirmBtn:'OK',
              isCancel:false
            }).then(() => {
              // this.network().draftQuotation()
            })
          }
        }
      })
      //
    },
  }
}
</script>
<style lang="scss" scoped>
  .policy-holder {
    .flex-right{
      justify-content: flex-end;
      margin-bottom:20px;
      ::v-deep .el-checkbox{
        margin-right: 8px;
      }
      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
        background:  #076081!important;
        border: 1px solid #076081!important;

      }
    }
    // .label {
    //         display: block;
    //         margin-bottom: 5px;
    //         font-size: 14px;
    //         font-weight: 500;
    //         color: #616266;
    //     }
    .edit-holder-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 20px;

      .card {
        padding-bottom: 40px;
      }
    }

    .title {
      display: block;
      padding-bottom: 15px;
      color: #616266;
      font-size: 20px;
      font-weight: 500;
    }

    .form-flex {
      display: flex;
      justify-content: space-between;

      & > .form-block {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .form-block {
      margin-bottom: 15px;

      & > span:first-child {
        display: block;
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .holder-title{
      margin-bottom: 16px;
      display: block;
    }
  }
</style>
