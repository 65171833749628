import { render, staticRenderFns } from "./insuredDetailForm.vue?vue&type=template&id=745256dd&scoped=true&"
import script from "./insuredDetailForm.vue?vue&type=script&lang=js&"
export * from "./insuredDetailForm.vue?vue&type=script&lang=js&"
import style0 from "./insuredDetailForm.vue?vue&type=style&index=0&id=745256dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "745256dd",
  null
  
)

export default component.exports